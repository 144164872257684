import { createDirectStore } from "direct-vuex";
import auth, { AuthState } from "./modules/auth";
import wiki, { WikiState } from "./modules/wiki";
import konyveles, { KonyvelesState } from "./modules/konyveles";
const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore({
  modules: {
    auth,
    wiki,
    konyveles
  }
});

// Export the direct-store instead of the classic Vuex store.
export default store;

// The following exports will be used to enable types in the
// implementation of actions and getters.
export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
};

export interface State {
  auth: AuthState;
  wiki: WikiState;
  konyveles: KonyvelesState;
  // The following lines enable types in the injected store '$store'.
}

export type AppStore = typeof store;
declare module "vuex" {
  // eslint-disable-next-line
  interface Store<S> {
    direct: AppStore;
  }
}