import { API } from ".";
import { UserLogin, User, DraftUser } from "./types";

export const UserAPI = {
    login: (loginPayload: UserLogin) => {
        return API.post("/auth/login", loginPayload);
    },
    logout: () => {
        return API.post("/auth/logout");
    },
    getUser: async (userId: number): Promise<User> => {
        const { data } = await API.get<User>(`/user/${userId}`);
        return data;
    },
    getUsers: async (): Promise<User[]> => {
        const { data } = await API.get<User[]>("/user");
        return data;
    },
    createUser: async (user: DraftUser): Promise<User> => {
        const { data } = await API.post<User>("/user", user);
        return data;
    },
    getClaims: async () => {
        const { data } = await API.get("/user/claims");
        return data;
    },
    findByUsername: async (username: string) => {
        const { data } = await API.post("/user/find-user", { username });
        return data;
    },
    findByEmail: async (email: string) => {
        const { data } = await API.post("/user/find-user", { email });
        return data;
    },
    updateUser: async (userId: number, user: Partial<User>): Promise<User> => {
        const { data } = await API.patch<User>(`/user/${userId}`, user);
        return data;
    },
    deleteUser: async (userId: number) => {
        await API.delete(`/user/${userId}`);
    },
    activateUser: async (userId: number): Promise<User> => {
        const { data } = await API.patch(`/user/${userId}/activate`);
        return data;
    }
};