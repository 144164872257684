import "./styles/quasar.scss";
import lang from "quasar/lang/hu.js";
import "@quasar/extras/roboto-font/roboto-font.css";
import "@quasar/extras/material-icons/material-icons.css";
import Dialog from 'quasar/src/plugins/Dialog.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';;
// To be used on app.use(Quasar, { ... })
export default {
    config: {},
    plugins: {
        Dialog,
        Notify,
        Loading
    },
    lang: lang,
};
