import { createRouter, createWebHistory, RouteRecordRaw, RouteLocationNormalized, NavigationGuardNext } from "vue-router";
import store from "@/store";

const onlyAnonymousCanAccess = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.state.auth.loggedIn) next({ name: "dashboard" });
  else next();
};

const onlyUserCanAccess = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (store.state.auth.loggedIn) next();
  else next({ name: "login" });
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: () => import("../layouts/UserAreaLayout.vue"),
    beforeEnter: onlyUserCanAccess,
    children: [
      {
        path: "/mobile/vegpontok",
        name: "service.mobile",
        component: () => import("../views/VegpontokMobileView.vue")
      },
      {
        path: "/vegpontok",
        name: "service",
        component: () => import("../views/VegpontokView.vue")
      },
      {
        path: "/hibajegyek",
        name: "client.tickets",
        component: () => import("../views/ticket/TicketView.vue")
      },
      {
        path: "/mobile/hibajegyek",
        name: "client.tickets.mobile",
        component: () => import("../views/ticket/TicketMobileView.vue")
      },
      {
        path: "/voip/charges",
        name: "voip.charges",
        component: () => import("../views/voip/ChargesView.vue")
      },
      {
        path: "/voip/calls",
        name: "voip.calls",
        component: () => import("../views/voip/CallsView.vue")
      },
      {
        path: "/voip/invoice",
        name: "voip.invoice",
        component: () => import("../views/voip/InvoiceView.vue")
      },
      {
        path: "/ugyfeltorles",
        name: "client.delete",
        component: () => import("../views/DeleteUCRMClient.vue")
      },
      {
        path: "/ac2/badsignal",
        name: "badsignal",
        component: () => import("../views/BadSignalView.vue")
      },
      {
        path: "/user",
        name: "users",
        component: () => import("../views/UsersView.vue")
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("../views/DashboardView.vue")
      },
      {
        path: "/wiki/article/create",
        name: "wiki.article.create",
        component: () => import("../views/wiki/CreateArticle.vue")
      },
      {
        path: "/wiki/article/:articleId",
        name: "wiki.article",
        component: () => import("../views/wiki/WikiArticleView.vue")
      },
      {
        path: "/wiki/csomagok",
        name: "wiki.csomagok",
        component: () => import("../views/wiki/PackagesPage.vue")
      },
      {
        path: "/radius/pppoe-users",
        name: "radius.pppoe-users",
        component: () => import("../views/radius/PPPOEUsersView.vue")
      },
      {
        path: "/konyveles/fuggo-utalasok",
        name: "konyveles.fuggo-utalasok",
        component: () => import("../views/konyveles/FuggoUtalasView.vue")
      },
      {
        path: "/pbx/ugyelet",
        name: "pbx.ugyelet",
        component: () => import("../views/ugyelet/UgyeletView.vue")
      },
      {
        path: "/",
        redirect: { name: "dashboard" }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import("../views/LoginView.vue"),
    beforeEnter: onlyAnonymousCanAccess
  },
  {
    path: "/500",
    name: "500",
    component: () => import("../views/InternalServerError.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/PageNotFound.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
