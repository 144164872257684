import { API } from ".";
import { WikiArticle, WikiArticleDraft } from "./types";


export const WikiAPI = {
    getArticleMenu: async () => {
        const { data } = await API.get("/wiki/article");
        return data;
    },
    getArticle: async (articleId: number) => {
        const { data } = await API.get(`/wiki/article/${articleId}`);
        return data;
    },
    postArticle: async (article: WikiArticleDraft) => {
        const { data } = await API.post("/wiki/article", article);
        return data;
    },
    patchArticle: async (articleId: number, article: Partial<WikiArticle>) => {
        const { data } = await API.patch(`/wiki/article/${articleId}`, article);
        return data;
    },
    deleteArticle: async (articleId: number) => {
        await API.delete(`/wiki/article/${articleId}`);
    }
};