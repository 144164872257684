import { ActionContext } from "vuex";
import { State } from "../index";
import { WikiArticleMenu } from "@/api/types";
import { WikiAPI } from "@/api/wiki";

export interface WikiState {
    articleMenu: WikiArticleMenu[];
}

type Context = ActionContext<WikiState, State>;

export default {
    namespaced: true as const,
    state: {
        articleMenu: []
    } as WikiState,
    getters: {},
    mutations: {
        setArticleMenu(state: WikiState, articles: WikiArticleMenu[]) {
            state.articleMenu = articles;
        }
    },
    actions: {
        async loadArticleMenu(context: Context) {
            const data = await WikiAPI.getArticleMenu();
            context.commit("setArticleMenu", data);
        }
    }
};