import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import store from "@/store";
import router from "@/router";

const config: AxiosRequestConfig = {
    withCredentials: true,
    baseURL: process.env.NODE_ENV === "development" ? `${window.location.protocol}//${window.location.hostname}:${process.env.VUE_APP_BACKEND_PORT || 5000}/api/v1` : window.location.protocol + "//" + window.location.host + "/api/v1",
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
    },

};
export const API: AxiosInstance = axios.create(config);

API.defaults.headers.post['X-CSRF-TOKEN'] = localStorage.getItem("CSRF_TOKEN");
API.defaults.headers.patch['X-CSRF-TOKEN'] = localStorage.getItem("CSRF_TOKEN");
API.defaults.headers.delete['X-CSRF-TOKEN'] = localStorage.getItem("CSRF_TOKEN");

API.interceptors.response.use((response: AxiosResponse) => {
    return response;
}, (error) => {
    if (error.response.status === 401 && !error.request.responseURL.includes("/auth/login")) {
        store.commit.auth.setLoggedIn(false);
        store.commit.auth.setUser(null);
        router.push({ name: "login" });
    }
    else if (error.response.status === 500) {
        router.replace({
            name: "500",
            query: {
                traceback: error.response.data.traceback,
            },
        });
    }
    return Promise.reject(error);
});