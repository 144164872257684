import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from './quasar-user-options';

import VueGoogleMaps from '@fawmi/vue-google-maps';

import "./styles/app.scss";
import Plugin from '@quasar/quasar-ui-qcalendar/src/QCalendarDay';
import '@quasar/quasar-ui-qcalendar/src/css/calendar-day.sass';
import 'v-calendar/dist/style.css';
import VCalendar from 'v-calendar';

createApp(App).use(Quasar, quasarUserOptions).use(store.original).use(router).use(
    VueGoogleMaps, { load: { key: process.env.VUE_APP_GMAP_API_KEY, language: "hu" } }
).use(Plugin).use(VCalendar).mount('#app');
