import { ActionContext } from "vuex";
import { State } from "../index";

import { UserAPI } from "@/api/user";
import { User, UserLogin } from "@/api/types";

export interface AuthState {
    user: null | User;
    loggedIn: boolean;
}

type Context = ActionContext<AuthState, State>;

export default {
    namespaced: true as const,
    state: {
        user: null,
        loggedIn: localStorage.getItem("loggedIn") == "true" ? true : false,
    } as AuthState,
    getters: {},
    mutations: {
        setUser(state: AuthState, user: User | null) {
            state.user = user;
        },
        setLoggedIn(state: AuthState, value: boolean) {
            localStorage.setItem("loggedIn", value.toString());
            state.loggedIn = value;
        },
    },
    actions: {
        async doLogin(context: Context, payload: UserLogin) {
            // Save CSRF Token
            try {
                await UserAPI.login(payload).then((resp) => {
                    localStorage.setItem("CSRF_TOKEN", resp.data.response.csrf_token);
                });
                await context.dispatch("getUserClaims");
                context.commit("setLoggedIn", true);
            }
            catch (exc) {
                return Promise.reject(exc);
            }
        },
        async doLogout(context: Context) {
            await UserAPI.logout();
            localStorage.removeItem("CSRF_TOKEN");
            context.commit("setLoggedIn", false);
            context.commit("setUser", null);
        },
        async getUserClaims(context: Context) {
            const data = await UserAPI.getClaims();
            context.commit("setLoggedIn", true);
            context.commit("setUser", data);
        }
    },

};