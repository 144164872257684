import { ActionContext } from "vuex";
import { State } from "../index";
import { IUtalasUCRMClient } from "@/api/konyveles/types";

export interface KonyvelesState {
    clientList: IUtalasUCRMClient[];
}

type Context = ActionContext<KonyvelesState, State>;

export default {
    namespaced: true as const,
    state: {
        clientList: []
    } as KonyvelesState,
    getters: {},
    mutations: {
        setClientList(state: KonyvelesState, value: IUtalasUCRMClient[]) {
            state.clientList = value;
        }
    }
};